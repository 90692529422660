import createReactComponent from '../createReactComponent';
export default createReactComponent('24-hours', 'Icon24Hours', [
  ['path', { d: 'M4 13c.325 2.532 1.881 4.781 4 6', key: 'svg-0' }],
  ['path', { d: 'M20 11a8.1 8.1 0 0 0 -15.5 -2', key: 'svg-1' }],
  ['path', { d: 'M4 5v4h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M12 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M18 15v2a1 1 0 0 0 1 1h1', key: 'svg-4' }],
  ['path', { d: 'M21 15v6', key: 'svg-5' }],
]);
