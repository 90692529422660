import createReactComponent from '../createReactComponent';
export default createReactComponent('parking', 'IconParking', [
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10 16v-8h2.667c.736 0 1.333 .895 1.333 2s-.597 2 -1.333 2h-2.667',
      key: 'svg-1',
    },
  ],
]);
