import createReactComponent from '../createReactComponent';
export default createReactComponent('message-report', 'IconMessageReport', [
  [
    'path',
    {
      d: 'M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 8v3', key: 'svg-1' }],
  ['path', { d: 'M12 14v.01', key: 'svg-2' }],
]);
